
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { OtelRateDayPopupLoading } from '@/modules/open-telemetry/decorators';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import type Day from '@/modules/common/types/day.type';
import type RatesService from '@/modules/rates/rates.service';
import type { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import type UserService from '@/modules/user/user.service';

import DayPopup from './day-popup/index.vue';
import { ColumnKey } from '../constants';
import type {
    RateTrendsService,
    ScanProps,
    TableOptions,
    TrendsPopup,
    TrendsWithIntraday,
    TrendsWithRatesDocument,
} from '../types';

@OtelRateDayPopupLoading(KEY.HotelRateTrendsService)
@Component({ components: { DayPopup } })
export default class HotelRatesDayPopup extends Vue implements TrendsPopup {
    /** This component is only for regular hotel rates page day popup */

    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.HotelRateTrendsService) private rateTrendsService!: RateTrendsService & TrendsWithRatesDocument & TrendsWithIntraday;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    mounted() {
        this.rateTrendsService.init(this.day);
    }

    get day() {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get tableColumns() {
        const entities = this.rateTrendsService.filters?.entities || [];
        const isAnyScreenshots = entities.some(entityId => this.rateTrendsService.trendsDocument?.trendData[0]?.data[entityId]?.screenshot);

        return {
            [ColumnKey.SCREENSHOT]: isAnyScreenshots
                ? {
                    label: '',
                    width: '2%',
                }
                : undefined,
            [ColumnKey.PRICE_TYPE]: this.rateTrendsService.filters?.priceType === PRICE_TYPE.LOWEST
                ? {
                    label: 'titles.type',
                    width: '4%',
                }
                : undefined,
            [ColumnKey.COLOR]: {
                label: '',
                width: '4%',
            },
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '41%',
            },
            [ColumnKey.RANK]: {
                label: 'titles.rank',
                width: '8%',
            },
            [ColumnKey.BASIC]: {
                label: '',
                width: '2%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '16%',
            },
            [ColumnKey.INTRADAY]: this.rateTrendsService.isIntraday
                ? {
                    label: 'titles.prevscan',
                    width: '13%',
                }
                : undefined,
            [ColumnKey.ROOM_NAME]: {
                label: 'titles.roomName',
                width: '25%',
            },
        };
    }

    get tableOptions() {
        return {
            isLinkShown: true,
            isLOSRestrictionShown: true,
            isDiffRoomNamesIconShown: true,
            isNetTotalCalcShown: true,
        } as TableOptions;
    }

    get tooltipColumns() {
        return {
            [ColumnKey.COLOR]: {
                label: '',
                width: '4%',
            },
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '40%',
            },
            [ColumnKey.RANK]: {
                label: 'titles.rank',
                width: '8%',
            },
            [ColumnKey.BASIC]: {
                label: '',
                width: '2%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '20%',
            },
            [ColumnKey.ROOM_NAME]: {
                label: 'titles.roomName',
                width: '25%',
            },
        };
    }

    get tooltipOptions() {
        return {
            isLOSRestrictionShown: true,
            maxEntities: 6,
        } as TableOptions;
    }

    get colors() {
        return this.settingsGeneralService.chartColors;
    }

    get compset() {
        return this.rateTrendsService.compset;
    }

    get scanProps() {
        const { compset, ratesDocument } = this.rateTrendsService;

        if (!compset || !ratesDocument) {
            return {
                lastScan: null,
                data: null,
                settings: null,
                compsetId: '',
                day: this.day,
                disabled: true,
                showScanDate: true,
            } as ScanProps;
        }

        const lastUpdateString = ratesDocument.checkinDates ? ratesDocument.checkinDates[this.day]?.updateDate : null;

        return {
            lastScan: lastUpdateString ? new Date(lastUpdateString) : null,
            day: this.day,
            data: ratesDocument,
            settings: this.ratesService.settings,
            compsetId: compset.id,
            disabled: false,
            showScanDate: true,
        } as ScanProps;
    }

    get isDocumentLoading() {
        if (this.rateTrendsService.isIntraday) {
            return this.rateTrendsService.ratesLoading.isLoading() || this.ratesService.isIntradayLoading;
        }

        return this.rateTrendsService.ratesLoading.isLoading();
    }

    get isChartLoading() {
        return this.isDocumentLoading || this.rateTrendsService.trendsLoading.isLoading();
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    handleScanTrigger() {
        this.ratesService.triggerScan(this.day);
    }

    handleDayChange() {
        this.$nextTick(() => this.rateTrendsService.update(this.day));
    }

    handlePriceShownChange(e: PRICE_SHOWN) {
        this.rateTrendsService.localPriceShown = e;
    }
}
